import XLSX from 'xlsx'

export class General {
  constructor(list, step = 10) {
    this.total = list.length
    this.list = list
    this.step = step
  }
  get length() {
    return this.list.length
  }
  append(item) {
    this.list.push(item)
  }
  done() {
    return this.list.length === 0
  }
  next() {
    return this.list.splice(0, this.step)
  }
}

function fixdata(data) {
  // 文件流转BinaryString
  var o = ''
  var l = 0
  var w = 10240
  for (; l < data.byteLength / w; ++l) {
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w))
    )
  }
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
  return o
}
export function readFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = function(ev) {
      const data = ev.target.result
      let workbook
      try {
        // eslint-disable-next-line no-debugger
        try {
          workbook = XLSX.read(btoa(fixdata(data)), {
            type: 'base64'
          })
        } catch (error) {
          window.console.log(error)
        }
        const wsname = workbook.SheetNames[0] //取第一张表
        const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])
        resolve(new General(ws))
        resolve()
      } catch (error) {
        window.console.warn(error)
        reject('解析失败')
      }
    }
    fileReader.readAsArrayBuffer(file)
  })
}
export const exportExcel = (config) => {
  // data参数数据格式为: [['单价', '数量', '总价'], [1, 2, 2], [2, 2, 4]]
  // data数组的第一个元素是表格内容的标题数组，剩余其他元素为数据内容数组
  const { data = [], cols, merges, fileName = '导出表格数据' } = config
  const worksheet = XLSX.utils.aoa_to_sheet(data)
  if (Array.isArray(cols) && cols.length) worksheet['!cols'] = cols
  if (Array.isArray(merges) && merges.length) worksheet['!merges'] = merges
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const writeOpt = config.write_opt || { bookType: 'xlsx' }
  const suffix = writeOpt.fileNameSuffix || writeOpt.bookType
  XLSX.writeFile(workbook, fileName + '.' + suffix, writeOpt)
}
