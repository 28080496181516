<template>
    <section class="create-page xa-view xa-container" >
        <main class="form-main xa-flex xa-container">
            <div class="form__title">添加资质</div>
            <div class="form__bz">
                <p>1、请先选择要导入的资质类型，并将人员名单导入系统进行解析</p>
                <p>2、导入后人员名单将解析到下方 [待导入名单] 页签，请确认无误后点击页面下方 [确定] 按钮提交资质信息</p>
                <p>3、导入后系统解析失败的请点击 [解析失败] 页签查看失败原因</p>
            </div>
            <el-form
            :model="ruleForm"
            :rules="batchRules"
            ref="ruleForm"
            class="el-form-wrap"
            size="medium"
            >
              <el-form-item label="资质类型" prop="zz_type" class="form-item-import">
                <el-select v-model="ruleForm.zz_type" 
                placeholder="请选择要导入的资质类型"
                clearable
                >
                  <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
                </el-select>
                <div class="xa-txt-placeholder xa-cell import-titles">
                    <div class="xa-txt-blue upload-btn xa-cursor">
                    <input
                        v-if="!isLoading"
                        type="file"
                        id="upload-btn"
                        @change="onFileChange"
                        accept=".xls,.xlsx"
                        style="width:100%"
                    />导入名单
                    </div>
                    <a
                    class="xa-txt-blue xa-cursor download-template"
                    href="./doc/资质-批量导入模板-2021-03-31.xlsx"
                    download="资质-批量导入模板.xlsx"
                    title="资质-批量导入模板.xlsx"
                    target="_blank"
                    >下载模板</a
                    >
                </div>
              </el-form-item>
              <el-form-item label="" prop="list" class="import-forms">
                  <div style="position:relative">
                      <AppLineLoading v-show="isLoading" />
                  </div>
                  <div v-if="fileNames.length">
                      <el-tag v-for="item in fileNames" :key="item" class="xa-mgr-16">{{
                      item
                      }}</el-tag>
                  </div>
                  <el-tabs>
                      <el-tab-pane>
                      <span slot="label"
                          >待导入名单
                          <span class="xa-txt-blue" v-if="ruleForm.list.length">{{
                          ruleForm.list.length
                          }}</span>
                      </span>
                      <DisplayUserInfo :datas="ruleForm.list" />
                      </el-tab-pane>
                      <el-tab-pane label="消息中心">
                      <span slot="label"
                          >解析失败
                          <span class="xa-txt-red" v-if="faillist.length">{{
                          faillist.length
                          }}</span>
                      </span>
                      <DisplayUserInfoFail :datas="faillist" />
                      </el-tab-pane>
                      <el-tab-pane label="导出失败" disabled>
                      <span slot="label">
                          <span class="xa-txt-red" v-if="faillist.length" @click="importFail">导出失败记录</span>
                      </span>
              
                      </el-tab-pane>
                  </el-tabs>
              </el-form-item>
            </el-form>
        </main>
        <div class="form-foot xa-cell">
            <el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
            <el-button plain size="small" @click="onCancel">取消</el-button>
        </div>
    </section>

</template>
<script>
import { readFile, General,exportExcel } from './utils'
import * as Apis from '@target/apis/school'
export default {
  components: {
    DisplayUserInfo: () => import('./components/DisplayUserInfo.vue'),
    DisplayUserInfoFail: () => import('./components/DisplayUserInfoFail.vue')
  },
  created(){
    this.getQualificationsData()
  },
  data() {
    return {
      showSelectCoupon: false,
      showSelectUser: false,
      isLoading: false,
      batchRules: {
        list: [{ required: true, message: '请录入名单', trigger: 'change' }],
        zz_type: [
          { required: true, message: '请选择资质类型', trigger: 'change' }
        ]
      },
      fileNames: [],
      faillist: [],
      ruleForm: {
        list: [], // 【批量发放】
        zz_type: ''
      },
      general: null,
      submitGeneral: null,
      options: [],
    }
  },
  methods: {
    gotoList() {
      this.$router.replace({
        path: '/home/statistics/datatable',
        query: {
          src: '/statistics/protector/home/protector_config',
          params: '[]',
          title: '资质管理',
          nav: 1
        }
      })
    },
    async getQualificationsData(){
      const result=await Apis.getQualifications()
      this.options=result
    },
    onCancel() {
      this.gotoList()
    },
    // 【批量】上传批量数据文件
    async onFileChange(e) {
      const files = e.target.files
      if (this.fileNames.includes(files[0].name) === false) {
        this.fileNames.push(files[0].name)
      }
      const mGeneral = await readFile(files[0])
      this.general = () => mGeneral
      this.isLoading = true
      this.gotoHandleFileData()
    },
    // 【批量】处理批量数据文件
    gotoHandleFileData() {
      if (this.general().done()) {
        this.general = null
        this.isLoading = false
        return
      }
      const list = this.general().next()
      this.handleData(list)
    },
    // 【批量】处理批量数据文件
    async handleData(list) {
      try {
        const result = await Apis.checkCouponAndUser(list)
        this.ruleForm.list.push(...result.filter(item => item.status))
        this.faillist.push(...result.filter(item => item.status === false))
      } catch (error) {
        this.$confirm(error.msg || error.message || error, '提示', {
          showCancelButton: false,
          confirmButtonText: '确定',
          type: 'error'
        })
      }
      setTimeout(() => {
        this.gotoHandleFileData()
      }, 0)
    },

    // 由父页面调用导入
    submitForm(formName = 'ruleForm') {
      // eslint-disable-next-line no-console
      // console.log(this.$refs[formName])
      this.$refs[formName].validate((valid) => {
        if (valid) {
            this.toStartSubmit(this.ruleForm.list)
        }
      })
    },
    // 【批量导入】
    async toStartSubmit(list) {
      const uiResolve = await this.askConfirm(
        `确定要提交？\n\r有${list.length}条数据要提交，请耐心等候。`
      )
      if (uiResolve) {
        this.submitGeneral = Object.create(new General(list, 1))
        setTimeout(() => {
          this.submitBatchData(uiResolve,list)
        }, 0)
      }
    },
    // 【批量导入】
    async submitBatchData(uiResolve,list) {
        try {
          await Apis.sendCoupon({type:this.ruleForm.zz_type,protecors:list})
          uiResolve.done(true, '导入成功!')
          setTimeout(() => {
          this.gotoList()
        }, 500)
        } catch (error) {
          uiResolve.done(false)
          this.$notify.error({
            title: '错误',
            message: error.msg || error.message
          })
        }
    },
    askConfirm(message) {
      const vm = this
      return new Promise(resolve =>
        this.$msgbox({
          title: '提示',
          message,
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.showCancelButton = false
              resolve({
                update(text) {
                  instance.message = text
                },
                done(result, msg) {
                  done()
                  if (result) {
                    vm.$message({
                      type: 'success',
                      message: msg || '操作成功！'
                    })
                  }
                }
              })
            } else {
              done()
            }
          },
          callback: (action, instance) => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = '确定'
          }
        })
      )
    },
    // 【导出失败】
    async importFail(){
      const newobjs={
        name: '姓名',
        mobile: '身份证号',
        idcard: '手机号码',
        message: '失败信息',
      }
      const tableDataResult = this.faillist
      const enKeys = Object.keys(newobjs)
            // eslint-disable-next-line no-console
      console.log(enKeys);
      const tableHead = enKeys.map(key => newobjs[key])
      // eslint-disable-next-line no-console
      console.log(tableHead)
      const tableDatas=tableDataResult.map(item=>{
        return obj2Arr(item, enKeys) 
      })
      function obj2Arr (params, keys) {
        return keys.map(key => params[key])
      }
      // eslint-disable-next-line no-console
      console.log(tableDatas);
      
      const newdatas = [tableHead, ...tableDatas]
      const outputData = {
        data: newdatas,
        fileName: '导出失败记录'
      }
      exportExcel(outputData)
    }
  }
}
</script>
<style lang="scss" scoped>
.create-page {
  position: relative;
  min-height: 100vh;
}
.form-main {
  margin: 20px 12px 0;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #999;
  /deep/ .form__title {
    margin-bottom: 20px;
    line-height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  /deep/ .el-form-wrap {
    min-width: 680px;
  }
  /deep/ .el-form-item {
    width: 780px;
  }
  /deep/.form-radio-group.xa-view .el-radio {
    & + .el-radio {
      margin-top: 8px;
    }
    display: flex;
    align-items: center;
    line-height: 36px;
  }
  /deep/.form-radio-group.xa-view .el-radio__label {
    padding-left: 10px;
  }
}
.product-dialog {
  /deep/ .el-dialog {
    min-width: 920px;
  }
  /deep/ .el-dialog__header {
    padding: 16px 15px;
    border-bottom: 1px solid #eee;
  }
  /deep/ .el-dialog__body {
    padding: 10px 15px 0;
  }
}
.form-foot {
  height: 40px;
  background-color: #fff;
  flex-shrink: 0;
  box-shadow: 0px 3px 6px 0px #000;
  justify-content: center;
}
.upload-btn {
    margin-left: 10px;
  position: relative;
  input {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}
.form__bz{
    p{
        margin-bottom: 20px;
    }
}
.form-item-import{
  margin-top: 30px;
}
.import-titles{
  display: inline-flex;
}
.download-template{
  margin-left: 20px;
}
</style>
