import request from '@/apis'

/**
 * 资质导入-检查用户信息
 * @param {Array} coupons [{'coupon_guid'=>'优惠券guid','mobile'=>'13333333333','cnt'=>2}]
 */
export async function checkCouponAndUser(coupons) {
  function valStr(str = '') {
    if (typeof str === 'string') return str.replace(/↵\n\r/g, '')
    return str
  }
  return request({
    method: 'post',
    url: '/statistics/protector/home/check_protector',
    headers: {
      'Content-type': 'application/json'
    },
    data: coupons.map(item => {
      let coupon_guid = item['姓名']
      let mobile = item['身份证号']
      let cnt = item['手机号码']
      return {
        name: valStr(coupon_guid),
        idcard: valStr(mobile),
        mobile: valStr(cnt)
      }
    })
  })
}

/**
 * 资质导入-检查用户信息
 * @param {Array} coupons [{'coupon_guid'=>'优惠券guid','mobile'=>'13333333333','cnt'=>2}]
 */
export async function sendCoupon(coupons) {
  return request({
    method: 'post',
    url: '/statistics/protector/home/save_protector',
    headers: {
      'Content-type': 'application/json'
    },
    data: coupons
  })
}

/**
 * 获取资质类型列表信息
 * @param {*} data
 */
export function getQualifications(data) {
  return request({
    method: 'get',
    url: '/statistics/protector/home/options',
    data
  })
}

